import * as React from "react"
import PropTypes from "prop-types"

const HtmlHeader = ({ paragraphData }) => {
  const HeadingLevel = paragraphData.field_header_level

  return (
    <HeadingLevel className="text-lg font-bold">
      {paragraphData.field_header_text.processed}
    </HeadingLevel>
  )
}

HtmlHeader.propTypes = {
  paragraphData: PropTypes.object,
}

export default HtmlHeader
