import * as React from "react"
import PropTypes from "prop-types"

const HtmlBlock = props => {
  const { paragraphData, additionalClasses } = props

  const defaultClasses = ["my-3"]

  if (paragraphData.field_html_text !== null) {
    function createMarkup() {
      return { __html: paragraphData.field_html_text.processed }
    }

    return (
      <div
        className={defaultClasses.concat(additionalClasses).join(" ")}
        dangerouslySetInnerHTML={createMarkup()}
      />
    )
  } else {
    return ""
  }
}

HtmlBlock.propTypes = {
  paragraphData: PropTypes.object,
  additionalClasses: PropTypes.array, // For adding additional classes to the wrapping element
}

export default HtmlBlock
