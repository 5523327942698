import * as React from "react"
import PropTypes from "prop-types"

const LinkListing = props => {
  const { paragraphData, additionalClasses } = props

  const defaultClasses = ["my-3"]

  if (paragraphData.field_links.length > 0) {
    return (
      <div className={defaultClasses.concat(additionalClasses).join(" ")}>
        <ul>
          {paragraphData.field_links.map((link, index) => {
            return (
              <li key={index}>
                <a
                  className="text-blue-700"
                  href={link.uri}
                  target="_blank"
                  rel="noreferrer"
                >
                  {link.title}
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    )
  } else {
    return ""
  }
}

LinkListing.propTypes = {
  paragraphData: PropTypes.object,
  additionalClasses: PropTypes.array, // For adding additional classes to the wrapping element
}

export default LinkListing
