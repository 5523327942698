import React, { useContext } from "react"
import Helmet from "react-helmet"
import { SettingsModalContext } from "./nav/settings"
import SiteHeaderGeneric from "./header/site-header-generic"
import { MenusProvider } from "../utils/MenusContext"
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"

const LayoutUpcomingMenu = ({ children, ...props }) => {
  const { feedTitle } = props
  const [settingsModalState] = useContext(SettingsModalContext)
  const { darkMode } = settingsModalState
  const darkClass = darkMode ? " dark" : ""
  return (
    <MenusProvider>
      <div className="w-full font-sans antialiased">
        {updateHead({ darkClass })}
        <div className="sticky top-0 z-[9999]">
          <SiteHeaderGeneric pageTitle={`${feedTitle} Upcoming Menu`} />
          <nav className="bg-gray-100 px-4 md:px-6 p-1.5 dark:bg-gray-600">
            <a
              href="/"
              data-type="call to action"
              data-context="upcoming menu"
              data-action="return to the dash"
              className="bg-transparent text-sm text-swat-red transition hover:bg-gray-300 dark:text-white dark:hover:bg-gray-700 px-2 py-1 rounded"
              rel="noopener"
              title="Link to The Dash"
            >
              <span className="mr-1.5 text-xs">
                <FontAwesomeIcon icon={faArrowLeft} className="inline-block" />
              </span>
              Return to The Dash
            </a>
          </nav>
        </div>

        <main id="main">
          <div className="grid min-h-screen grid-cols-1 px-3 dark:bg-gray-900 dark:text-white md:px-6">
            {children}
          </div>
        </main>
      </div>
    </MenusProvider>
  )
}

// Updates <head> with darkmode dependent settings
const updateHead = options => {
  const { darkClass } = options
  return (
    <Helmet
      bodyAttributes={{
        class: darkClass,
      }}
      meta={[
        {
          name: "theme-color",
          content: darkClass ? "#111827" : "#b43135",
        },
        {
          name: "background-color",
          content: darkClass ? "#111827" : "#b43135",
        },
      ]}
    />
  )
}

export default LayoutUpcomingMenu
