import React from "react"
import PropTypes from "prop-types"
// Paragraphs
import HtmlHeader from "./paragraphs/html-header"
import HtmlBlock from "./paragraphs/html-block"
import LinkListing from "./paragraphs/link-listing"

const SecondaryContent = ({ secondaryContentData }) => {
  return secondaryContentData.map((paragraph, index) => {
    switch (paragraph.internal.type) {
      case "paragraph__html_header":
        return <HtmlHeader paragraphData={paragraph} key={index} />
      case "paragraph__html_block":
        return (
          <HtmlBlock
            paragraphData={paragraph}
            additionalClasses={["prose", "text-sm", "dark:prose-dark"]}
            key={index}
          />
        )
      case "paragraph__link_listing":
        return (
          <LinkListing
            paragraphData={paragraph}
            additionalClasses={["prose", "text-sm", "dark:prose-dark"]}
            key={index}
          />
        )
      default:
        return ""
    }
  })
}

SecondaryContent.propTypes = {
  secondaryContentData: PropTypes.array,
}

export default SecondaryContent
