import React, { useEffect, useState, useContext } from "react"
import slugify from "slugify"
import { DateTime } from "luxon"
import { useStaticQuery, graphql } from "gatsby"
// Layout And Templates
import AwsRum from "../page-layout/aws-rum"
import LayoutUpcomingMenu from "../page-layout/layout-upcoming-menu"
import { SettingsProvider } from "../page-layout/nav/settings"
import Seo from "../page-layout/seo"
import SecondaryContent from "../sections/secondary-content"
import ChevronToggle from "../page-layout/ui/chevron-toggle"
// Utils
import DataProvider from "../utils/DataProvider"
import { MenuContent } from "../utils/dietaryLabelsUtil"
import DietaryKey from "../sections/keys-legends/dietary-key"
import RangedTimeElement from "../utils/RangedTimeElement"
import swatDatesUtil from "../utils/swatDatesUtil"
import { UserProvider } from "../utils/UserContext"
import { MenusContext } from "../utils/MenusContext"

export default function UpcomingMenu({ pageContext }) {
  const [hasMounted, setHasMounted] = useState(false)
  const [showDietaryKey, setShowDietaryKey] = useState(true)
  const rfc3339Fmt = "yyyy-MM-dd'T'HH:mm:ssZZ"
  const today = DateTime.now()
  const queryName = pageContext.feedSource
  const queryVariables = {
    calendarId: pageContext.feedId,
    order: "ASC",
    timeMin: today.startOf("day").toFormat(rfc3339Fmt),
    timeMax: today.startOf("day").plus({ day: 7 }).toFormat(rfc3339Fmt),
  }
  const sectionData = useStaticQuery(graphql`
    query MenusSecondaryContent {
      allNodeContentSection(filter: { field_section_type: { eq: "menus" } }) {
        edges {
          node {
            id
            drupal_id
            title
            field_section_type
            relationships {
              field_secondary_content {
                ... on paragraph__html_header {
                  id
                  drupal_id
                  internal {
                    type
                  }
                  field_header_text {
                    format
                    processed
                    value
                  }
                  field_header_level
                }
                ... on paragraph__html_block {
                  id
                  drupal_id
                  internal {
                    type
                  }
                  field_html_text {
                    processed
                  }
                }
                ... on paragraph__link_listing {
                  id
                  drupal_id
                  internal {
                    type
                  }
                  field_links {
                    title
                    uri
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  useEffect(() => {
    setHasMounted(true)
  }, [])
  if (!hasMounted) {
    return null
  }

  const nodeData = sectionData.allNodeContentSection.edges[0].node
  const hasSecondaryContent =
    nodeData.relationships.field_secondary_content.length >= 1

  return (
    <UserProvider>
      <SettingsProvider>
        <LayoutUpcomingMenu feedTitle={pageContext.feedTitle}>
          <AwsRum />
          <Seo title={`${pageContext.feedTitle} Upcoming Menu`} />
          <div className="grid-str mt-3 grid grid-rows-1 gap-5 md:grid-cols-section-aside">
            <div className="grid grid-cols-1 md:order-1 order-2">
              <DataProvider
                queryName={queryName}
                queryVariables={queryVariables}
                key={pageContext.feedTitle}
              >
                <UpcomingMenuItems
                  feedTitle={pageContext.feedTitle}
                  today={today}
                />
              </DataProvider>
            </div>
            <div
              data-type="secondary content"
              data-context="menus section"
              className="md:order-2 order-1"
            >
              <div className="border rounded-md border-gray-200 p-3 dark:border-gray-700">
                {hasSecondaryContent && (
                  <section className="md:block hidden">
                    <SecondaryContent
                      secondaryContentData={
                        nodeData.relationships.field_secondary_content
                      }
                    />
                  </section>
                )}
                <section>
                  <button
                    data-type="toggle"
                    data-context="upcoming menu dietary label sidebar"
                    data-action={showDietaryKey ? "collapse" : "expand"}
                    data-label="Dietary Information"
                    type="button"
                    onClick={() => setShowDietaryKey(!showDietaryKey)}
                  >
                    <h1 className="text-lg font-bold inline-block">
                      Dietary Information
                    </h1>
                    <ChevronToggle
                      chevronClasses={["inline-block", "ml-2"]}
                      toggle={showDietaryKey}
                    />
                  </button>
                  <div
                    className={`my-2 text-sm ${
                      showDietaryKey ? "block" : "hidden"
                    }`}
                  >
                    <DietaryKey filterProps={{ isUpcomingMenu: true }} />
                  </div>
                </section>
              </div>
            </div>
          </div>
        </LayoutUpcomingMenu>
      </SettingsProvider>
    </UserProvider>
  )
}

const UpcomingMenuItems = props => {
  const { error, loading, data, feedTitle, today } = props
  const [menusState, menusDispatch] = useContext(MenusContext)
  const { toggledLabels } = menusState

  if (!loading && !error) {
    if (data.result.data.length === 0) {
      return "No upcoming menu items found. Please check back soon."
    } else {
      const groupedByDate = (uniqueDates, upcomingMenuData) =>
        uniqueDates.map((el, i) => {
          const filteredMenuData = upcomingMenuData.filter(
            d => d.mealDate === el
          )
          return {
            [el]: filteredMenuData,
          }
        })
      const upcomingMenuData = data.result.data.map((el, i) => {
        const startofDay = DateTime.fromISO(el.startdate).startOf("day")
        return {
          mealDate: startofDay.toISO(),
          ...el,
        }
      })
      const uniqueDates = [
        ...new Set(upcomingMenuData.map(item => item.mealDate)),
      ]

      const processedMenuData = groupedByDate(
        uniqueDates,
        upcomingMenuData
      ).map((el, i) => {
        const groupKey = Object.keys(el)[0]
        const meals = el[groupKey].map((meal, m) => {
          const { __typename, title, startdate, enddate, description } = meal
          return (
            <div key={m}>
              <h2 className="mt-3 pb-0.5 text-lg font-bold text-gray-800 underline decoration-primary decoration-2 underline-offset-4 dark:text-gray-200">
                {__typename === "CbordMenuData" && title + ","}{" "}
                <RangedTimeElement
                  start={DateTime.fromISO(startdate)}
                  end={DateTime.fromISO(enddate)}
                />
              </h2>
              <div className="prose mt-3 px-3 text-black dark:prose-dark">
                <MenuContent
                  description={description}
                  toggledLabels={toggledLabels}
                />
              </div>
            </div>
          )
        })
        const date = DateTime.fromISO(groupKey)
        const dateElement = (
          <time dateTime={date.toISODate()}>
            {date.toFormat("cccc, MMM'.' d")}
          </time>
        )
        const sectionId = slugify(
          feedTitle + "-" + swatDatesUtil(date.toFormat("cccc, MMM'.' d")),
          {
            lower: true,
            strict: true,
          }
        )

        return (
          <section
            key={i}
            id={sectionId}
            className="border-b-2 border-gray-100 py-3 first:pt-0 last:border-b-0 dark:border-gray-700"
          >
            <h1 className="mb-2 text-2xl font-bold text-gray-800 dark:text-gray-200">
              {today.toRelativeCalendar() === date.toRelativeCalendar() ? (
                <>Today ({dateElement})</>
              ) : (
                dateElement
              )}
            </h1>
            {meals}
          </section>
        )
      })
      return processedMenuData
    }
  } else {
    return null
  }
}
